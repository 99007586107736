const loginForm = () => {
    const loginToggle = document.querySelector('[data-login-target]');

    if (loginToggle != null && loginToggle != undefined) { // Only show if user isn't logged in
        const loginTarget = document.querySelector(
            `#${loginToggle.getAttribute('data-login-target')}`
        );
        const displayName = document.querySelector('#Username');

        loginToggle.addEventListener('click', (e) => {
            e.preventDefault();

            if (window.outerWidth < 1024) {
                window.location.href = loginToggle.href;
            } else {
                if (loginTarget.hasAttribute('hidden')) {
                    loginTarget.removeAttribute('hidden');
                    loginToggle.setAttribute('aria-expanded', true);
                    loginToggle.classList.add('js-active');
                    displayName.focus();
                } else {
                    loginTarget.setAttribute('hidden', '');
                    loginToggle.setAttribute('aria-expanded', false);
                    loginToggle.classList.remove('js-active');
                }
            }
        });
    }

  


};

export { loginForm as default };
