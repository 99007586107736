const svgInjector = () => {
  const animatedSvgs = document.querySelectorAll('.svg-animation');

  for (const [, animatedSvg] of animatedSvgs.entries()) {
    const src = animatedSvg.getAttribute('src');
    if (!src.endsWith('.svg')) {
      console.warn(
        `WARNING: Attempted to load file ${src} but it isn't an SVG.`
      );
      return;
    }

    const request = new XMLHttpRequest();
    request.open('GET', src, true);

    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        const parser = new DOMParser();
        const result = parser.parseFromString(request.responseText, 'text/xml');
        const inlinedSvg = result.getElementsByTagName('svg')[0];
        const attributes = animatedSvg.attributes;

        Array.prototype.slice.call(attributes).forEach((attribute) => {
          if (attribute.name !== 'src' && attribute.name !== 'alt') {
            inlinedSvg.setAttribute(attribute.name, attribute.value);
          }
        });

        inlinedSvg.setAttribute('role', 'img');

        if (attributes.alt) {
          inlinedSvg.setAttribute('aria-labelledby', 'title');

          const title = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'title'
          );
          const titleText = document.createTextNode(attributes.alt.value);

          title.appendChild(titleText);
          inlinedSvg.insertBefore(title, inlinedSvg.firstChild);
        }

        if (animatedSvg.parentNode) {
          animatedSvg.parentNode.replaceChild(inlinedSvg, animatedSvg);
        }
      } else {
        console.error(`ERROR: Failed to load file ${src}`);
      }

      request.onerror = () => {
        console.error(`ERROR: Failed to load file ${src}`);
      };
    };

    request.send();
  }
};

export { svgInjector as default };
