const ui = () => {
  // Make div buttons into actual buttons
  const submitButtons = document.querySelectorAll('div [role=button]');
  if (submitButtons.length > 0) {
    submitButtons.forEach(function(btn) {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.closest('form').submit();
      });
    });
  }

  // Teaser
  const teaserForm = document.getElementById('TeaserForm');
  if (teaserForm != null) {
    $('#TeaserForm .error').hide();
    $('#ContentTeaserPass').hide();
    $(document).on('submit', '#TeaserForm', function(e) {
      e.preventDefault();

      var url = $(this).attr('action');
      $.post(url, $(this).serialize(), function(res) {
        if (res.pass) {
          $('#ContentTeaserPass').show();
          $('#ContentTeaserContent').hide();
          $('#ContentTeaserHint').removeClass('is-active');
          $('#TeaserForm .error').html('Correct Code');
        } else {
          $('#TeaserForm .error').html('Incorrect Code');
        }
        $('#TeaserForm .error').show();
      });
    });
    $(document).on('click', '#TeaserForm a', function(e) {
      e.preventDefault();
      $('#TeaserForm').submit();
    });
  }

  // Trace
  if (document.getElementById('TraceWriteups') != null) {
    $(document).on('click', '#TracePagination a', function(e) {
      e.preventDefault();
      var page = parseInt($(this).html());
      var cat = $('#TraceCategory').val();
      drawWriteups(page, cat);
    });
    $(document).on('click', '#TracePrevious', function(e) {
      e.preventDefault();
      var page = parseInt($('#TracePagination a.active').html()) - 1;
      var cat = $('#TraceCategory').val();
      drawWriteups(page, cat);
    });
    $(document).on('click', '#TraceNext', function(e) {
      e.preventDefault();
      var page = parseInt($('#TracePagination a.active').html()) + 1;
      var cat = $('#TraceCategory').val();
      drawWriteups(page, cat);
    });
    $(document).on('change', '#TraceCategory', function(e) {
      var cat = $('#TraceCategory').val();
      drawWriteups(1, cat);
    });
    $(document).on('click', '.like-post', function(e) {
      e.preventDefault();
      var id = $(this).attr('data-id');
      var s = $(this).find('span');
      $.get('/trace/addvote?id=' + id, function(res) {
        if (res.success) {
          s.html(res.count);
        } else {
          alert('You cannot vote for this post');
        }
      });
    });
    drawWriteups(1);
  }
};

const drawWriteups = (page, cat) => {
  var url = `/trace/FetchWriteups?page=${page}`;
  if ((cat != undefined) & (cat != '')) {
    url = url + '&cat=' + cat;
  }

  const writeUpMarkup = (w) => ` <div class="m-b-30 post-item">
                                        <h3 class="trace-title"><a href="/trace/post?id=${
                                          w.id
                                        }">• ${w.displayName} ${
    w.title != null ? w.title : ''
  }</a></h3>
                                        <p>
                                            ${w.writeup}
                                        </p>
                                        <div class="like-post" data-id="${
                                          w.id
                                        }">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.87 109.84"><defs><style>.icon-like-1{stroke-width:6px;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="icon-like-1 svg-icon-fill svg-icon-stroke" d="M153.51,75.66,80.43,4.2,7.36,75.66H44.81v31.18h68.41V75.66h40.29Z"/></g></g></svg>
                                            <span>${w.votes}</span>
                                        </div>
                                    </div>`;

  $.get(url, function(res) {
    $('#TraceWriteups').html('');
    res.results.forEach(function(r) {
      $('#TraceWriteups').append(writeUpMarkup(r));
    });
    $('#TracePagination').html('');
    for (var i = 1; i <= res.totalPages; i++) {
      $('#TracePagination').append(
        `<a href="#" class="${i == page ? 'active' : ''}">${i}</a>`
      );
    }
    if (page <= 1) {
      $('#TracePrevious').hide();
    } else {
      $('#TracePrevious').show();
    }
    if (page >= res.totalPages) {
      $('#TraceNext').hide();
    } else {
      $('#TraceNext').show();
    }
    if (res.totalPages <= 1) {
      $('#TracePagination').hide();
    }
  });
};

export { ui as default };
