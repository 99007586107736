import '../scss/foundation.scss';
import '../scss/main.scss';

//import * as AOS from '../aos/dist/aos.js';
//import AOS from 'aos';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';

import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';

import svgInjector from './utils/svgInjector';
import loginForm from './utils/loginForm';
import Accordions from './utils/accordion';
import ui from './utils/ui';

document.addEventListener('DOMContentLoaded', function() {
  $(document).foundation();

  // run the SVG injector which pulls all SVG images with the class '.svg-animation'
  // and injects them into the page as inline SVGs ready to animate
  svgInjector();

	// mobile hamburger menu
	$('.hamburger').on('click', function(e) {
    $('.hamburger').toggleClass('menu-is-active is-active');
    $('.nav-menu-container').toggleClass('is-active');
    e.preventDefault();
  });
  
  // button hint
  $('.button-hint').click(function(){
    return false;
  });
  $('.button-hint').on('click', function(e) {
    $('.content-main').toggleClass('is-hidden');
    $('.content-hint').toggleClass('is-active');
    e.preventDefault();
  });

  // button success
  $('.button-success').on('click', function(e) {
    $('.content-main').removeClass('is-active');
    $('.content-hint').removeClass('is-active');
    $('.button-hint').addClass('is-disabled');
    $('.content-success').addClass('is-active');
    window.scrollTo(0,0,);
    e.preventDefault();
  });  

  // AOS
  //AOS.init();

    ui();

  // Login form toggle and validation
  loginForm();

  // accordion
  const accordion = new Accordions();
  accordion.init();

  // glitch
  const glitch = $('.glitch');
  setTimeout(() => {
    glitch.show();
  }, 400);

  setTimeout(() => {
    glitch.hide();
  }, 1450);

  setTimeout(() => {
    glitch.show();
  }, 1750);

  setTimeout(() => {
    glitch.hide();
  }, 1900);

  setTimeout(() => {
    glitch.show();
  }, 1954);

  setTimeout(() => {
    glitch.hide();
  }, 1980);

  // back to top button

  // var btn = $('#topbutton');

  // $(window).scroll(function() {
  // 	if ($(window).scrollTop() > 300) {
  // 		btn.addClass('show');
  // 	} else {
  // 		btn.removeClass('show');
  // 	}
  // });

  // btn.on('click', function(e) {
  // e.preventDefault();
  // $('html, body').animate({scrollTop:0}, '300');
  // });
});
