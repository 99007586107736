class Accordions {
    init() {
        // Accordions
        const accordions = document.querySelector('.accordions');
        if (accordions) {
            var accordionLinks = document.querySelectorAll(".acc-link");
            for (var i = 0; i < accordionLinks.length; i++) {
                var accordionLink = accordionLinks[i];
                accordionLink.onclick = function (e) {
                    e.preventDefault();
                    this.classList.toggle("open");
                    if(this.classList.contains('open')){
                        this.setAttribute('aria-expanded', 'true');
                    }else{
                        this.setAttribute('aria-expanded', 'false');
                    }                    
                };
            }
        }
    }
  
  }
  
  export default Accordions;